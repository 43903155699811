


























































































































import Vue from "vue";
import LoginLayout from "@/components/frontend/LoginLayout.vue";
import {Step, stepFromResult} from "@/models/Step";
import Warning from "@/components/frontend/login/Warning.vue";
import Bind from "@/components/frontend/login/Bind.vue";
import TwoFactor from "@/components/frontend/login/TwoFactor.vue";
import LoggedIn from "@/components/frontend/login/LoggedIn.vue";
import PolicyConsent from "@/components/frontend/login/PolicyConsent.vue";
import MarketingConsent from "@/components/frontend/login/MarketingConsent.vue";
import AdTransition from "@/components/frontend/login/AdTransition.vue";
import Error from "@/components/frontend/login/Error.vue";
import IdpRedirect from "@/components/frontend/login/IdpRedirect.vue";
import Notification from "@/components/frontend/login/Notification.vue";
import Fetch from "@/util/Fetch";
import {copyParams} from "@/util/Url";
import Stats from "@/util/Stats";
import PostRedirect from "@/components/frontend/login/PostRedirect.vue";
import {Policy} from "@/graphql/generated/internal/types";

const api = process.env.VUE_APP_API_PATH ?? "";
const context = process.env.VUE_APP_WEB_CONTEXT ?? "";


export default Vue.extend({
  components: {
    LoginLayout,
    LoggedIn,
    Warning,
    Bind,
    TwoFactor,
    PolicyConsent,
    AdTransition,
    Error,
    PostRedirect,
    IdpRedirect,
    Notification,
    MarketingConsent
  },
  data: () => ({
    step: undefined as unknown as Step,
    messages: [] as string[],
    username: "",
    password: "",
    lt: "",
    error: "",
    welcomeMessage: "",
    capslock: "",
    centralUrl: "",
    centralUrlBase: "",
    idp: "",
    idpUrl: "",
    redirectTime: 10,
    responseParams: [],
    policies: [] as Policy[],
    listId: "",
    listName: "",
    validationRules: [(value: string) => !!value || "Required"],
    rules: [] as { (value: string): boolean | string; }[],
    state: {
      redirected: false,
      loading: false,
      fatalError: false,
      disabled: false,
      invalidAccountType: false,
      layoutHidden: false,
      binding: false,
      autofilled: false,
      transitioningToCentral: false,
      passwordResetRequested: false,
    },
    autofillDetectInterval: 0,
    adTransitionDaysRemaining: 0,
    thirdPartyCookiesEnabled: undefined,
    oneLoginSiteName: null

  }),
  computed: {
    isLoggedIn: function (): boolean {
      return this.step === Step.LOGGED_IN;
    },
    isWarning: function (): boolean {
      return this.step === Step.WARN;
    },
    isPasswordExpired: function (): boolean {
      return this.step === Step.PASSWORD_EXPIRED;
    },
    isBind: function (): boolean {
      return this.step === Step.BIND;
    },
    isTwoFactor: function (): boolean {
      return this.step === Step.TWO_FACTOR;
    },
    isPolicyConsentRequired: function (): boolean {
      return this.isCentral && this.step === Step.POLICY_CONSENT_REQUIRED;
    },
    isMarketingConsentRequired: function (): boolean {
      return this.isCentral && this.step === Step.MARKETING_CONSENT_REQUIRED;
    },
    isAdTransition: function (): boolean {
      return this.isCentral && this.step === Step.AD_TRANSITION;
    },
    isPostRedirect: function (): boolean {
      return this.step === Step.POST_REDIRECT;
    },
    isError: function (): boolean {
      return this.step === Step.ERROR;
    },
    isPreviousLogin: function (): boolean {
      return this.step === Step.EXTERNAL_IDP && !!this.idp && !!this.idpUrl;
    },
    isNotificationRequired: function(): boolean {
      return this.step === Step.NOTIFICATION_REQUIRED;
    },
    isCentral(): boolean {
      return process.env.VUE_APP_PACK === "central";
    },
    action(): string {
      return api + context + "login";
    },
    usernameLabel() {
      if(this.isCentral){
        return "E-mail or OneLogin ID";
      }
      return "IMS Username";
    }

  },
  created(): void {
    if (document.getElementById("serverdata")!.textContent! === "$data") {
      return;
    }
    const serverdata = JSON.parse(document.getElementById("serverdata")!.textContent!);
    this.oneLoginSiteName = serverdata.siteName;
    this.step = Step[serverdata.step as keyof typeof Step];
    this.lt = serverdata.lt;
    this.welcomeMessage = serverdata.welcomeMessage;
    this.error = serverdata.error;
    this.state.fatalError = !!serverdata.fatalError;
    this.centralUrl = serverdata.central;
    this.centralUrlBase = this.centralUrl.substring(0, this.centralUrl.indexOf("/login"));
    this.redirectTime = serverdata.redirectTime;
    this.adTransitionDaysRemaining = serverdata.adTransitionDaysRemaining;
    this.idp = serverdata.idp;
    this.idpUrl = serverdata.idpUrl;
    if (serverdata.responseParams) {
      this.responseParams = serverdata.responseParams;
    }
    if (!this.isCentral) {
      this.check3rdPartyCookiesEnabled(this.centralUrlBase + "/cookietest.html");
    }

    const url = new URL(window.location.href);
    const uid = url.searchParams.get("uid");
    if (uid) {
      this.username = uid;
      this.state.redirected = true;
    }
    window.addEventListener("unload", () => this.state.transitioningToCentral = false);
  },
  methods: {
    getRedirectUrl() {
      if(this.centralUrlBase){
        const url = new URL(`${this.centralUrlBase}/login`);
        url.searchParams.set('method', 'POST');
        url.searchParams.set('service', this.getService());
        return url.toString();
      }
    },
    login: function (): void {
      this.validate().then(valid => {

        if (!valid) return;

        this.disableRules();

        this.error = "";
        this.state.loading = true
        const postData = {
          "username": this.username,
          "password": this.password,
          "lt": this.lt,
          "clientInfo": Stats.clientInfo(),
          "redirect": (!this.state.binding).toString(),
          "redirectType": "client"
        }
        Fetch.postForm(this.action, postData)
            .then(response => response.json())
            .then(json => this.tryBind(json))
            .catch(error => {
              this.state.loading = false;
              this.handleGeneralError(true, error, undefined);
            });
      });
    },
    onLogin(json: any): Promise<any> {
      const step = json.step ? Step[json.step as keyof typeof Step] : stepFromResult(json.result);

      if (step === Step.LOGGED_IN) {
        this.redirect(json.redirectUrl);
      } else {
        this.step = step;

        if (json.warnings) {
          json.warnings.forEach((w: string) => this.messages.push(w))
        }
        if (json.message) {
          this.messages.push(json.message)
        }
        if (json.responseParams) {
          this.responseParams = json.responseParams;
        }
        if (json.policies) {
          this.policies = json.policies;
        }
        if(json.listId){
          this.listId = json.listId;
        }
        if(json.listName){
          this.listName = json.listName;
        }

        if (this.step === Step.TWO_FACTOR) {
          this.renewTicket();
        } else if (this.step === Step.ERROR) {
          this.error = json.error;
          this.reset();
        }
        this.state.loading = false;
      }
      return Promise.resolve(json);
    },
    loggedIn: function (): void {
      this.redirect();
    },
    bindingDone: function (): void {
      this.isCentral ? this.redirect() : this.redirect(this.centralUrl);
    },
    reset: function (): Promise<any> {
      this.password = "";
      this.step = Step.LOGIN;
      return this.renewTicket();
    },
    adTransitionDeclined: function (): void {
      this.step = Step.LOGIN;
      this.login();
    },
    adTransitionApproved: function (): Promise<any> {
      this.password = "";
      this.step = Step.LOGIN;
      return this.renewTicket();
    },
    bindSelected: function (): void {
      this.step = Step.LOGIN;
      this.state.binding = true;
    },
    continueLogin: function (loginResult: any) : Promise<any> {
      return Promise.resolve(loginResult)
          .then(json => this.bind(json))
          .then(json => this.onLogin(json));
    },
    tryBind: function (loginResult: any): Promise<any> {
      return Promise.resolve(loginResult)
          .then(json => this.bind(json))
          .then(json => this.onLogin(json));
    },
    bind: function (loginResult: any): Promise<any> {
      const step = loginResult.step ? Step[loginResult.step as keyof typeof Step] : stepFromResult(loginResult.result);
      if (!this.state.binding || (Step.LOGGED_IN !== step && Step.WARN !== step)) return Promise.resolve(loginResult);
      const bindTicket = new URL(window.location.href).searchParams.get("bind") ?? "";
      const postData = {
        "bind": bindTicket,
        "service": this.getService()
      }
      return Fetch.postForm(api + context + "imsapi/bind", postData)
          .then(() => this.state.binding = false)
          .then(() => loginResult.redirectUrl = this.centralUrl)
          .then(() => loginResult)
          .catch(e => {
            if (console?.error) {
              console.error(e);
            }
            Fetch.apiErrorMessage(e).then(message => this.error = message);
          });
    },
    renewTicket: function (): Promise<any> {
      this.state.disabled = true;
      return Fetch.get(api + context + "imsapi/loginTicket")
          .then(json => {
            this.lt = json.lt;
            this.state.disabled = false;
          })
          .catch(error => {
            this.handleGeneralError(false, error, "Unable to get new login ticket");
          });
    },
    url: function (url: string): string {
      return copyParams(url);
    },
    validate: function (): Promise<boolean> {
      this.enableRules();
      return Vue.nextTick().then(() => (this.$refs.form as Vue & { validate: () => boolean }).validate());
    },
    enableRules: function (): void {
      this.rules = this.validationRules;
    },
    disableRules: function (): void {
      this.rules = [];
    },
    checkCapsLock(event: KeyboardEvent | MouseEvent): void {
      const isOn = event.getModifierState("CapsLock");
      this.capslock = isOn ? "Your caps lock key is on!" : "";
    },
    checkUsername(): void {
      if (this.isCentral) {
        if(this.username && (this.username.indexOf("\\") == -1 && this.username.indexOf("@") == -1)) {
          this.error = "Username does not contain a prefix nor is an email. Include your login domain prefix to login into central OneLogin";
          this.state.invalidAccountType = true;
        } else {
          this.error = "";
          this.state.invalidAccountType = false;
        }
        return;
      }

      if (this.username && (this.username.indexOf("\\") > -1 || this.username.indexOf("@") > -1)) {
        if (!this.centralUrl) {
          this.error = "Looks like you're trying to log in with a central OneLogin account. Central OneLogin has not been configured for this site.";
        } else {
          const pingUrl = this.centralUrlBase + "/servicestatus";
          Fetch.get(pingUrl)
              .then(json => {
                if (json.serverstatus === "OK") {
                  this.error = "Looks like you are trying to log in with a central OneLogin account. Click 'Continue with OneLogin' to login with central account.";
                  this.state.invalidAccountType = true;
                } else {
                  window.location.assign(this.url("unbind"));
                }
              })
              .catch(() => {
                window.location.assign(this.url("unbind"));
              });
        }
      } else {
        this.error = "";
        this.state.invalidAccountType = false;
      }

    },
    redirect(redirectUrl?: string): void {
      if (redirectUrl) {
        window.location.assign(redirectUrl);
      } else {
        window.location.reload();
      }
    },
    getService: function (): string {
      const params = new URL(window.location.href).searchParams;
      return params.get("service") ?? "";
    },
    clearAutofillInterval: function (): void {
      if (this.autofillDetectInterval) {
        clearInterval(this.autofillDetectInterval);
        this.autofillDetectInterval = 0;
      }
    },
    check3rdPartyCookiesEnabled(iFrameUri: string): void {
      let messageHandler = (event: MessageEvent) => {
        try {
          let data = JSON.parse(event.data);
          if ('thirdPartyCookiesEnabled' in data) {
            window.removeEventListener('message', messageHandler);
            document.body.removeChild(frame);
            this.thirdPartyCookiesEnabled = data['thirdPartyCookiesEnabled'];
          }
        } catch (e) {
          if (!(e instanceof SyntaxError) && console && console.error) { // to avoid spamming console with meaningless SyntaxErrors
            console.error(e);
          }
        }
      }

      window.addEventListener('message', messageHandler);
      const frame: HTMLIFrameElement = document.createElement('iframe');
      frame.src = iFrameUri;
      if (frame.sandbox) {
        frame.sandbox.add("allow-scripts");
        frame.sandbox.add("allow-same-origin");
      }
      frame.style.display = 'none';
      document.body.appendChild(frame);
    },

    handleGeneralError(reset: boolean, error: any, customMessage: string | undefined) {
      if (console?.error) {
        console.error(error);
      }
      if (customMessage) {
        this.error = customMessage;
      } else {
        this.error = "Unable to access OneLogin"
      }
      if (reset) {
        this.reset();
      }
    },
    sendResetPasswordRequest: function (): void {
      this.disableRules();
      this.error = "";
      this.state.loading = true;

      Fetch.postForm(api + context + "forgotpassword", {
        "username": this.username,
      }).then(() => {
        this.state.passwordResetRequested = true;
      }).catch(error => {
        if (console?.error) {
          console.error(error);
        }
      }).finally(() => {
        this.state.loading = false;
      })
    },
  },
  mounted: function (): void {
    this.state.loading = false;
    if (this.isLoggedIn) {
      this.redirect();
    }

    this.autofillDetectInterval = window.setInterval(() => {
      if (document.querySelectorAll("input[type=\"password\"]:-webkit-autofill").length > 0) {
        this.state.autofilled = true;
      }
    }, 100);

    setTimeout(() => {
      this.clearAutofillInterval();
    }, 2000);
  },
  watch: {
    password(): void {
      this.state.autofilled = false;
    },
    'state.autofilled'(): void {
      this.clearAutofillInterval();
      if (this.state.autofilled) {
        this.checkUsername();
        const labels = document.getElementsByClassName("v-label");
        for (let i = 0; i < labels.length; i++) {
          labels.item(i)?.classList.add("v-label--active");
        }
      }
    },
    thirdPartyCookiesEnabled(): void {
      if (!this.thirdPartyCookiesEnabled) {
        this.error = "Your browser currently has third party cookies disabled. OneLogin recommends third party cookies to be enabled for better integration with services."
        // browser-specific
        const ua = navigator.userAgent;
        if (ua.includes("Edg")) {
          this.error = this.error.concat("\n\n", "To enable third party cookies in Microsoft Edge: \n\
          1. Go to browser settings\n\
          2. Go to \"Cookies and site permissions\"\n\
          3. Choose \"Manage and delete cookies and site data\"\n\
          4. Ensure \"Block third-party cookies\" is unselected");
        } else if (ua.includes("Chrome")) {
          this.error = this.error.concat("\n\n", "To enable third party cookies in Google Chrome: \n\
          1. Go to browser settings\n\
          2. Go to \"Privacy and security\"\n\
          3. Choose \"Allow all cookies\"");
        } else if (ua.includes("Firefox")) {
          this.error = this.error.concat("\n\n", "To enable third party cookies in Mozilla Firefox: \n\
          1. Go to browser settings\n\
          2. Go to \"Privacy & Security\"\n\
          3. Under \"Enhanced Tracking Protection\", select the Custom radio button\n\
          4. Ensure that either \"Cookies\" is not selected, or the chosen option is not \"All third-party cookies\" or \"All cookies\"");
        }
      }
    }
  }
})
